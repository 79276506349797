<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Availability" subtitle="Quick search the availability of specific games." />

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :is-call="true" v-if="!collection.length">

		<app-content-call icon="search" title="No search term" text="Enter a game name in the search bar to check availability." />

	</app-content-body>

	<app-content-body :loading="is.loading && is.initialised" v-if="collection.length" :is-grid="true">

		<app-card :brand="brandText(item)" :brandTheme="brandTheme(item)" :notLinked="true" :image="item.bgg_game.image" v-for="item in collection" :key="item.id" :title="item.name" :subtitle="(item.version) ? item.version : '-'" :tab="item.shelf">

			<app-card-action v-if="!item.notification" tooltip="Click to join waiting list" v-on:click="onNotificationJoinClick(item.bgg_game.id)" icon="plus" :disabled="item.status === 1" />
			<app-card-action v-if="item.notification" :loading="is.leaving === item.notification.id" :danger="true" tooltip="Click to leave waiting list" v-on:click="onNotificationLeaveClick(item.notification.id)" icon="minus" />
			
		</app-card>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			filter: {
				search: ''
			},
			is: {
				leaving: false
			},
			endpoint: 'convention/checkout/availability',
			live: 'convention/checkout/availability'
		}

	},

	computed: {

		query: function() {

			return this.$route.query.search

		},

	},

	watch: {

		query: function() {

			this.filter.search = this.$route.query.search

		}

	},

	methods: {

		brandText: function(item) {

			if (item.notification) {

				return 'Waiting list'

			} else if (item.status === 1) {

				return 'Available'

			} else if (item.status === 2) {

				return 'Checked out'

			}
			
		},

		brandTheme: function(item) {

			if (item.notification) {

				return 'blue'

			} else if (item.status === 1) {

				return 'green'

			} else if (item.status === 2) {

				return 'red'

			}
			
		},

		onSetupFilter: function() {

			if (this.$route.query.search) this.filter.search = this.$route.query.search

		},

		onNotificationJoinClick: function(id) {

			this.$router.push({
				name: 'Convention.Checkout.Notifications.Edit',
				params: {
					id: 'new'
				},
				query: {
					game: id
				}
			})

		},

		onNotificationLeaveClick: function(id) {

			this.is.leaving = id

			this.$api.delete(['convention/checkout/notification', id]).then(function() {

				this.is.leaving = false

				this.$notify({
					message: ['You have successfully left the waiting list']
				})

				this.load()

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>